import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";
import React from "react";
import useStations from "../utils/hooks/useStations";

const h1Text = "Regional Weather Events";

const RegionalWeatherEvents = () => {
  const { station } = useStations();
  return (
    <Layout station={station} h1Text={h1Text}>
      <Seo title="Regional Weather Events" />
      <div
        className="container mx-auto container-article xl:pt-6 lg:px-4 xl:px-12"
        style={{ maxWidth: "600px" }}
      >
        <div style={{ width: "80%", margin: "0 auto" }}>
          <h2 style={{ textAlign: "center" }}>2024 Events</h2>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <strong>26 April 2024</strong> | Freeze event (
              <a
                href="https://data.nysipm.org/newa/weather-events/2024042612-freeze/freeze-ending-2024042612-map.html"
                target="blank"
              >
                View report
              </a>
              )
            </li>
            <li>
              <strong>25 April 2024</strong> | Freeze event (
              <a
                href="https://data.nysipm.org/newa/weather-events/2024042512-freeze/freeze-ending-2024042512-map.html"
                target="blank"
              >
                View report
              </a>
              )
            </li>
          </ul>
        </div>
        <div style={{ width: "80%", margin: "0 auto" }}>
          <h2 style={{ textAlign: "center" }}>2023 Events</h2>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <strong>18 May 2023</strong> | Freeze event (
              <a
                href="https://data.nysipm.org/newa/weather-events/2023051812-freeze/freeze-ending-2023051812-map.html"
                target="blank"
              >
                View report
              </a>
              )
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default RegionalWeatherEvents;
